.control-section {
  margin: 0 auto;
}

#target_dash .dashboardParent {
  width: 100%;
}
.e-dashboardlayout.e-control .e-panel {
  background-image: 1px solid;
  border-radius: 16px !important;
  border: 1px solid rgb(134, 134, 137) !important;
}

.e-control .e-panel .e-panel-content {
  text-align: center;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.e-dashboardlayout *::-webkit-scrollbar {
  width: 10px;
}

.e-dashboardlayout *::-webkit-scrollbar-track {
  background: transparent;
}

.e-dashboardlayout.e-control .e-panel .e-panel-header {
  font-size: 10rem;
  text-align: center;
}
.e-calendar {
  margin: auto;
}

.e-chart {
  padding: 0;
  display: inline-block;
  margin: auto !important;
  max-width: fit-content;
}

