.excel-export.e-schedule .e-schedule-toolbar .e-toolbar-item.e-today {
  display: none;
}

.e-schedule .e-timeline-view .e-child-node,
.e-schedule .e-timeline-month-view .e-child-node {
  vertical-align: top;
  padding-top: 15px;
}

.e-schedule .e-month-view .e-date-header {
  width: 100%;
}

.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  /* color: #000; */
}

.e-schedule .e-timeline-month-view .e-appointment,
.e-schedule .e-month-view .e-appointment,
.e-schedule .e-month-agenda-view .e-appointment,
.e-schedule .e-timeline-view .e-appointment {
  background-color: none !important;
  background: none !important;
  border-radius: none !important;
  height: 22px !important;
}

.schedule-cell-dimension.e-schedule
  .e-timeline-month-view
  .e-content-wrap
  table
  col {
  width: 200px;
}

.schedule-cell-dimension.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 220px !important;
}

.e-schedule .e-vertical-view .e-header-cells,
.e-schedule .e-vertical-view .e-work-cells {
  width: 120px !important;
}

.e-start-container,
.e-end-container,
.e-timezone-container,
.e-all-day-container {
  display: none;
}

body {
  cursor: default !important;
}
